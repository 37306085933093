import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h1>
    <p>{`We're happy to announce a Beta version of LiveSession's REST API. From now, you'll be able to retrieve information about your data programmatically.
You can for example use the API for deeper analyze of your data collected by LiveSession.`}</p>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <p>{`This API provides access to some LiveSession existing resources which you usually use using a web browser. List of today supported resources are here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/rest-api/reference#list-sessions"
        }}>{`List sessions`}</a></li>
    </ul>
    <h2 {...{
      "id": "using-the-api"
    }}>{`Using the API`}</h2>
    <p>{`The API is HTTP-based and is hosted at `}<a parentName="p" {...{
        "href": "https://api.livesession.io/v1"
      }}>{`https://api.livesession.io/v1`}</a>{`.
To access your data, you have to get a personal access token that will be sent together with your request through headers to authenticate as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: Bearer <YOUR_PERSONAL_ACCESS_TOKEN>
`}</code></pre>
    <p>{`For example, to list the user sessions, you can perform the following request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET \\
  https://api.livesession.io/v1/sessions \\
  -H 'content-type: application/json' \\
  -H 'Authorization: Bearer {YOUR_PERSONAL_ACCESS_TOKEN}'
`}</code></pre>
    <h2 {...{
      "id": "creating-a-personal-access-token"
    }}>{`Creating a personal access token`}</h2>
    <ol>
      <li parentName="ol">{`Go to the settings of your account.`}</li>
      <li parentName="ol">{`Click `}<strong parentName="li">{`API Tokens`}</strong>{` from left menu.`}</li>
      <li parentName="ol">{`In the upper-right corner of `}<strong parentName="li">{`API Tokens`}</strong>{` page, click the creation button.`}</li>
      <li parentName="ol">{`Give your token a descriptive name.`}</li>
      <li parentName="ol">{`Select the website you want to access through the REST API.`}</li>
      <li parentName="ol">{`Select the scopes you'd like to grant this token.`}</li>
      <li parentName="ol">{`At the end click on confirm button.`}</li>
    </ol>
    <h2 {...{
      "id": "rate-limits"
    }}>{`Rate limits`}</h2>
    <p>{`Note that by default all tokens are set to 2 Queries Per Second (QPS).
This is a per token limit.`}</p>
    <h2 {...{
      "id": "further-steps"
    }}>{`Further steps`}</h2>
    <p>{`Issues, bugs and questions can be reported by reaching us out at `}<a parentName="p" {...{
        "href": "mailto:hello@livesession.io"
      }}>{`hello@livesession.io`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      